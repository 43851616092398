import isBlank from '~/utils/isBlank'
import validateEmail from '~/commun/utils/validateEmail'

export default {
  props: {
    /** Input value */
    value: {
      type: [Number, String],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    withoutCustomization: {
      type: Boolean,
      default: false,
    },
    isToUpdateValue: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // keep native input value
      inputValue: '',
      errors: [],
    }
  },
  watch: {
    inputValue(newValue) {
      this.$emit('input', newValue)
    },
    value(newValue) {
      if (this.$el.id !== 'case-type-group-selector' && this.isToUpdateValue) {
        this.inputValue = newValue
      }
    },
  },
  created() {
    if (this.value !== null) this.inputValue = this.value
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0
    },
  },
  methods: {
    validate() {
      this.errors = []

      if (
        !this.withoutCustomization &&
        this.required &&
        isBlank(this.inputValue)
      ) {
        this.errors.push(this.$t('product_form.errors.required'))
      } else if (
        this.customType &&
        this.customType === 'email' &&
        !validateEmail(this.inputValue)
      ) {
        this.errors.push(
          this.$t('newsletter.errors.invalid_email', {
            email: this.inputValue,
          })
        )
      }

      return {
        hasErrors: this.hasErrors,
        errors: this.errors,
      }
    },
  },
}
