<template>
  <v-content class="login-page" size="thin">
    <div v-if="checkingLogin" class="login-page__loading">
      <logo-loading />
    </div>
    <auth-box v-else />
  </v-content>
</template>
<script>
import VContent from '~/components/shared/v-content/VContent'
import LogoLoading from '~/components/loadings/logo'
import AuthBox from '~/components/auth-box/AuthBox'

export default {
  components: {
    VContent,
    LogoLoading,
    AuthBox,
  },
  data() {
    return {
      checkingLogin: false,
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters.isLogged
    },
  },
  async created() {
    this.checkingLogin = true
    try {
      await this.$store.dispatch('checkUserLogin')
    } catch (err) {
      this.$displayTopMessage(this.$t('action_error'), {
        type: 'error',
      })
    }

    this.checkingLogin = false
    if (this.isLogged && process.client) {
      this.$router.push('/')
    }
  },
  head() {
    return {
      title: this.$t('pages_seo.login_title'),
    }
  },
  async middleware({ store, redirect }) {
    await store.dispatch('checkUserLogin')

    if (store.getters.isLogged) {
      return redirect('/')
    }
  },
}
</script>
<style lang="scss" scoped>
.login-page {
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 60px;
  }
}
</style>
